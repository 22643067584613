import React from "react";
import { Divider, Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

function Documents(props) {
  const { data, documentList, bankDetails } = props;

  return (
    <Grid container className="flexDisplay" direction="column">
      <Grid item xs={12} className="marginBottom40 flexDisplay" direction="row">
        <span className="fontSize1_5rem fontWeightBold">Document</span>
      </Grid>

      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Aadhaar card number</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.aadhaar_number}</span>
        </Grid>
      </Grid>
      {documentList ? (
        documentList.aadhaarCard ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Aadhar Front</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.aadhaarCard ? (
                  <a
                    href={documentList.aadhaarCard.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.aadhaarCard.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}{" "}
            </Grid>
            <Grid item xs={5}>
              {documentList.aadhaarCard.reupload === 1 ? (
                <Chip
                  label="Reupload"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#fcdcc3",
                    color: "#ff6e48",
                    fontWeight: "600",
                  }}
                />
              ) : documentList.aadhaarCard.verified === 1 ? (
                <Chip
                  label="Verified"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#bbffd6",
                    color: "#52b892",
                    fontWeight: "600",
                  }}
                />
              ) : (
                <Chip
                  label="Verification required"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#feedb6",
                    color: "#ffab19",
                    fontWeight: "600",
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}

      {documentList ? (
        documentList.aadhaarCardBack ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Aadhar Back</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.aadhaarCardBack ? (
                  <a
                    href={documentList.aadhaarCardBack.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.aadhaarCardBack.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}
            </Grid>
            <Grid item xs={5}>
              <span>
                {documentList.aadhaarCardBack.reupload === 1 ? (
                  <Chip
                    label="Reupload"
                    size="small"
                    color="primary"
                    style={{
                      backgroundColor: "#fcdcc3",
                      color: "#ff6e48",
                      fontWeight: "600",
                    }}
                  />
                ) : documentList.aadhaarCardBack.verified === 1 ? (
                  <Chip
                    label="Verified"
                    size="small"
                    color="primary"
                    style={{
                      backgroundColor: "#bbffd6",
                      color: "#52b892",
                      fontWeight: "600",
                    }}
                  />
                ) : (
                  <Chip
                    label="Verification required"
                    size="small"
                    color="primary"
                    style={{
                      backgroundColor: "#feedb6",
                      color: "#ffab19",
                      fontWeight: "600",
                    }}
                  />
                )}
              </span>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}
      {documentList ? (
        documentList.drivingLicense ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Driving license</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.drivingLicense ? (
                  <a
                    href={documentList.drivingLicense.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.drivingLicense.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}{" "}
            </Grid>
            <Grid item xs={5}>
              {documentList.drivingLicense.reupload === 1 ? (
                <Chip
                  label="Reupload"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#fcdcc3",
                    color: "#ff6e48",
                    fontWeight: "600",
                  }}
                />
              ) : documentList.drivingLicense.verified === 1 ? (
                <Chip
                  label="Verified"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#bbffd6",
                    color: "#52b892",
                    fontWeight: "600",
                  }}
                />
              ) : (
                <Chip
                  label="Verification required"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#feedb6",
                    color: "#ffab19",
                    fontWeight: "600",
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}
      {documentList ? (
        documentList.panCard ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">PAN Card</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.panCard ? (
                  <a
                    href={documentList.panCard.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.panCard.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}{" "}
            </Grid>
            <Grid item xs={5}>
              {documentList.panCard.reupload === 1 ? (
                <Chip
                  label="Reupload"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#fcdcc3",
                    color: "#ff6e48",
                    fontWeight: "600",
                  }}
                />
              ) : documentList.panCard.verified === 1 ? (
                <Chip
                  label="Verified"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#bbffd6",
                    color: "#52b892",
                    fontWeight: "600",
                  }}
                />
              ) : (
                <Chip
                  label="Verification required"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#feedb6",
                    color: "#ffab19",
                    fontWeight: "600",
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}

      {/* <Grid item xs={12} style={{marginTop:"12px"}} className="flexDisplay height20" direction="row" >
                <Grid item xs={4}>
                    <span className="fontWeightBold">ID proof</span>
                </Grid>         
                <Grid item xs={8}>
                    {documentList ?
                        documentList.idProof?<a
                            href={documentList.idProof.file}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {documentList.idProof.name}
                        </a>:<span>Not Available</span>:<span>Not Available</span>
                    }     
                </Grid>                
            </Grid>  */}
      {documentList ? (
        documentList.idProof ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">ID proof</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.idProof ? (
                  <a
                    href={documentList.idProof.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.idProof.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}
            </Grid>
            <Grid item xs={5}>
              {documentList.idProof.reupload === 1 ? (
                <Chip
                  label="Reupload"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#fcdcc3",
                    color: "#ff6e48",
                    fontWeight: "600",
                  }}
                />
              ) : documentList.idProof.verified === 1 ? (
                <Chip
                  label="Verified"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#bbffd6",
                    color: "#52b892",
                    fontWeight: "600",
                  }}
                />
              ) : (
                <Chip
                  label="Verification required"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#feedb6",
                    color: "#ffab19",
                    fontWeight: "600",
                  }}
                />
              )}
            </Grid>{" "}
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}

      {documentList ? (
        documentList.currentAddressProof ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Current Address Proof</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.currentAddressProof ? (
                  <a
                    href={documentList.currentAddressProof.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.currentAddressProof.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}
            </Grid>
            <Grid item xs={5}>
              {documentList.currentAddressProof.reupload === 1 ? (
                <Chip
                  label="Reupload"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#fcdcc3",
                    color: "#ff6e48",
                    fontWeight: "600",
                  }}
                />
              ) : documentList.currentAddressProof.verified === 1 ? (
                <Chip
                  label="Verified"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#bbffd6",
                    color: "#52b892",
                    fontWeight: "600",
                  }}
                />
              ) : (
                <Chip
                  label="Verification required"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#feedb6",
                    color: "#ffab19",
                    fontWeight: "600",
                  }}
                />
              )}
            </Grid>{" "}
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}

      {documentList ? (
        documentList.vehicleRC ? (
          <Grid item xs={12} className="flexDisplay height40" direction="row">
            <Grid item xs={4}>
              <span className="fontWeightBold">Vehicle RC</span>
            </Grid>
            <Grid item xs={3}>
              {documentList ? (
                documentList.vehicleRC ? (
                  <a
                    href={documentList.vehicleRC.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {documentList.vehicleRC.name}
                  </a>
                ) : (
                  <span>Not Available</span>
                )
              ) : (
                <span>Not Available</span>
              )}
            </Grid>
            <Grid item xs={5}>
              {documentList.vehicleRC.reupload === 1 ? (
                <Chip
                  label="Reupload"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#fcdcc3",
                    color: "#ff6e48",
                    fontWeight: "600",
                  }}
                />
              ) : documentList.vehicleRC.verified === 1 ? (
                <Chip
                  label="Verified"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#bbffd6",
                    color: "#52b892",
                    fontWeight: "600",
                  }}
                />
              ) : (
                <Chip
                  label="Verification required"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#feedb6",
                    color: "#ffab19",
                    fontWeight: "600",
                  }}
                />
              )}
            </Grid>{" "}
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}

      <Divider className="marginBottom40" />
      <Grid item xs={12} className="marginBottom40">
        <span className="fontSize1_5rem fontWeightBold">
          Bank Account Details
        </span>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40">
        <Grid item xs={4}>
          <span className="fontWeightBold">Account holder name</span>
        </Grid>
        <Grid item xs={8}>
          <span>
            {bankDetails ? bankDetails.accountHolderName : "Not Available"}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Account number</span>
        </Grid>
        <Grid item xs={8}>
          <span>
            {bankDetails ? bankDetails.accountNumber : "Not Available"}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">IFSC code</span>
        </Grid>
        <Grid item xs={8}>
          <span>{bankDetails ? bankDetails.ifscCode : "Not Available"}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Account proof</span>
        </Grid>
        <Grid item xs={8}>
          {bankDetails && bankDetails.accountProof ? (
            <a href={bankDetails.accountProof} rel="noreferrer" target="_blank">
              {`Account proof`}
            </a>
          ) : (
            <span>Not Available</span>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Documents;
