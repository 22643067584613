import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider, FormHelperText, Tooltip } from "@material-ui/core";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import AutoSelect from "./InputComponents/AutoSelect";
import "./index.css";
import axios from "axios";
import UrlLinks from "../../../UrlLinks";
import { Link, useHistory, useParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import styles from "./index.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { AppData } from "../../../context/context";
import { useContext } from "react";
import closeSvg from "../../../assets/close.svg";
import Download from "./Group 3238.svg";
import LocationModal from "../LocationModal";
import LocationComponent from "./LocationComponent";
import TextEditor from "./InputComponents/TextEditor";

const locationDataTemplate = "/locationData-template.xlsx";

const useStyles = makeStyles({
  clientName: {
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1C75BC",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #1C75BC",
      },
    },
  },
});

const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < 1024 * 1024) {
    return (sizeInBytes / 1024).toFixed(2) + " KB";
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
};

const JobConfigForm = (data) => {
  const classes = useStyles();
  const history = useHistory();
  const [laoding, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    projectName: "",
    jobTitle: "",
    jobType: "",
    jobTypeLocation: "",
    jobDesc: "",
    locationType: "",
    locations: [],
    shiftTimings: "",
    locationSearch: "",
    attachmentName: "",
    attachmentSize: "",
    attachmentNeeded: false,
  });
  const [isNotEditable, setIsNotEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [goodToSave, setGoodToSave] = useState(false);
  const [showLocationList, setShowLocationList] = useState(false);
  const [eligibilityData, setEligibilityData] = useState(null);
  const [getData, setGetData] = useState("");
  const [ageToggle, setAgeToggle] = useState(false);
  const [genderToggle, setGenderToggle] = useState(false);
  const [educationToggle, setEducationToggle] = useState(false);
  const [languageToggle, setLanguageToggle] = useState(false);
  const [assetsToggle, setAssetsToggle] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [selectedLocationFile, setSelectedLocationFile] = useState([]);
  const [excel, setExcel] = useState(null);
  const [fileSizeErr, setFileSizeErr] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const fileInputRef = useRef(null);
  const locationFileInputRef = useRef(null);
  const {
    jobConfigMode,
    setJobConfigMode,
    eligibilityJobId,
    setEligibilityJobId,
    setEligibilityMode,
  } = useContext(AppData);
  const [validationErr, setValidationErr] = useState({
    projectDescription: false,
    projectKey: false,
    projectName: false,
  });
  const [initialProjectKey, setInitialProjectKey] = useState("");
  const [validationMessage, setValidationMessage] = useState({
    descriptionError: "",
    projectKeyError: "",
  });
  const [fieldEmptyCheck, setFieldEmptyCheck] = useState(false);

  const [optionList, setOptionList] = useState({
    clientList: [],
    verticalList: [],
    projectNameList: [],
    projectOwnerList: [],
    shiftTimings: [],
    locationList: [],
  });
  const { jobId } = useParams();

  // Function to check if all required values are present
  const checkFormData = () => {
    const requiredFields = [
      "projectName",
      "jobTitle",
      "jobType",
      "jobTypeLocation",
      "jobDesc",
      "shiftTimings",
    ];

    const jobTypeValue = formData["jobTypeLocation"];
    if (jobTypeValue === "6") {
      if (selectedLocationFile.length === 0) {
        requiredFields.push("locations");
      }
      requiredFields.push("locationType");
    }

    const allFieldsPresent = requiredFields.every((field) => {
      if (field === "locations") {
        return jobTypeValue === "6" ? formData[field].length > 0 : true;
      }
      if (Array.isArray(formData[field])) {
        return formData[field].length > 0;
      } else {
        return formData[field] !== "";
      }
    });

    return allFieldsPresent;
  };

  useEffect(() => {
    const isGoodToSave = checkFormData();
    setGoodToSave(isGoodToSave);
  }, [JSON.stringify(formData), selectedLocationFile]);

  useEffect(() => {
    if (formData.jobTypeLocation === "5" && jobId === "add") {
      setFormData((prev) => ({
        ...prev,
        locations: [],
        locationSearch: "",
        attachmentName: "",
        attachmentSize: "",
      }));
      setSelectedAttachments([]);
    }
  }, [formData.jobTypeLocation]);

  const makeProjectNameList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.projects_key,
        name: el.project_name,
        projectId: el.projects_id,
      });
      return true;
    });
    return list;
  };

  const makeShiftList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.id,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeLocationList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.id,
        name: el.name,
      });
      return true;
    });
    return list;
  };

  const handleOpenUploadedFile = () => {
    if (attachment) {
      const fileURL = URL.createObjectURL(attachment);
      window.open(fileURL, "_blank");
    } else {
      console.log("No file uploaded.");
    }
  };

  const validateFormData = (formData) => {
    // Loop through the keys in the formData object
    for (const key in formData) {
      if (key === "description") continue;
      if (key === "is_has_job") continue;
      if (!formData[key]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setFieldEmptyCheck(!validateFormData(formData));
  }, [formData]);

  useEffect(async () => {
    let currentFormMode = jobConfigMode;

    if (jobId !== "add") {
      currentFormMode = "view";
    }

    await Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.projectListing.mainListing}`
    ).then((response) => {
      setOptionList((prev) => ({
        ...prev,
        projectNameList: makeProjectNameList(response.data.response),
      }));
    });

    await Axios.get(`${UrlLinks.baseUrl}task-job/jobConfigValues`).then(
      (response) => {
        setOptionList((prev) => ({
          ...prev,
          shiftTimings: makeShiftList(response.data.shift),
        }));
      }
    );

    if (jobId !== "add") {
      await Axios.get(`${UrlLinks.baseUrl}task-job/${jobId}`).then(
        (response) => {
          const newFormData = JSON.parse(response.data.page1);
          const newEligibilityData = JSON.parse(response.data.page2);
          const locations = response.data.locationArray;

          if (locations && locations.length > 0) {
            newFormData.locations = [...locations];
          }

          if (response.data.attachment && response.data.attachment.length > 0) {
            newFormData.attachmentNeeded = true;
          }

          setFormData(newFormData);
          setGetData(response.data);
          setEligibilityData(newEligibilityData);
          setAgeToggle(response.data.ageMust === 1 ? true : false);
          setGenderToggle(response.data.genderMust === 1 ? true : false);
          setEducationToggle(response.data.educationMust === 1 ? true : false);
          setLanguageToggle(response.data.langMust === 1 ? true : false);
          setAssetsToggle(response.data.assetMust === 1 ? true : false);
        }
      );
    }

    setJobConfigMode(currentFormMode);
    setPageLoading(false);
  }, []);

  const getLocationList = () => {
    if (
      formData &&
      formData.locationSearch &&
      formData.locationSearch.length > 2
    ) {
      Axios.get(
        `${UrlLinks.baseUrl}task-job/getLocations?key=${formData.locationSearch}&locationType=${formData.locationType}&projectId=${formData.projectName.projectId}`
      ).then((response) => {
        setOptionList((prev) => ({
          ...prev,
          locationList: response.data.response
            ? makeLocationList(response.data.response)
            : [],
        }));
      });
    }
  };

  useEffect(() => {
    if (formData.locationType) {
      getLocationList();
    }
  }, [formData.locationType, formData.locationSearch]);

  const handleInputChange = (e, type) => {
    if (type === 1) {
      setFormData((prev) => ({
        ...prev,
        jobTitle: e.target.value.replace(/[^a-zA-Z ]/gi, ""),
      }));
    }
    if (type === 3) {
      setFormData((prev) => ({ ...prev, jobTypeLocation: e.target.value }));
    }
    if (type === 4) {
      setFormData((prev) => ({ ...prev, jobType: e.target.value }));
    }
    if (type === 5) {
      setFormData((prev) => ({ ...prev, jobDesc: e.target.value }));
    }
    if (type === 6) {
      setFormData((prev) => ({
        ...prev,
        locationType: e.target.value,
        locations: [],
      }));
    } else if (type === 2) {
      const pattern = /^[a-zA-Z0-9]{1,7}$/;
      const value = e.target.value;
      let list = [];

      optionList.projectNameList.map((item) => {
        list.push(item.id);
      });
      if (!pattern.test(e.target.value)) {
        setValidationErr({ projectKey: true });
        setValidationMessage((prev) => ({
          ...prev,
          projectKeyError: "Invalid Location. Please check the info",
        }));
      } else if (list.includes(value)) {
        if (jobConfigMode === "edit" && initialProjectKey === e.target.value) {
          setValidationErr({ projectKey: false });
        } else {
          setValidationErr({ projectKey: true });
        }
        setValidationMessage((prev) => ({
          ...prev,
          projectKeyError: "Key already exists",
        }));
      } else {
        setValidationErr({ projectKey: false });
        setValidationMessage((prev) => ({ ...prev, projectKeyError: "" }));
      }
      setFormData((prev) => ({ ...prev, projectKey: e.target.value }));
    } else if (type === 7) {
      if (e.target.value.length >= 300) {
        setValidationErr({ projectDescription: true });
        setValidationMessage((prev) => ({
          ...prev,
          descriptionError: "Word limit exhausted, please check the info",
        }));
      } else {
        setValidationErr({ projectDescription: false });
        setValidationMessage((prev) => ({ ...prev, descriptionError: "" }));
      }
      setFormData((prev) => ({ ...prev, description: e.target.value }));
    }
  };

  useEffect(() => {
    setInitialProjectKey(formData.projectKey);
  }, [jobConfigMode]);

  const handleSaveBtn = () => {
    try {
      const mainData = { ...formData };
      mainData.attachmentName =
        selectedAttachments && selectedAttachments.length > 0
          ? selectedAttachments[0].name
          : formData.attachmentName;
      mainData.attachmentSize =
        selectedAttachments && selectedAttachments.length > 0
          ? formatFileSize(selectedAttachments[0].size)
          : formData.attachmentSize;
      const formData1 = new FormData();
      formData1.append("attachment_file_1", attachment);
      formData1.append("file", excel);
      setIsLoading(true);

      const body = JSON.stringify({
        jobDesc: mainData.jobDesc,
        jobId: jobId !== "add" ? jobId : null,
        jobTitle: mainData.jobTitle,
        jobType: +mainData.jobType,
        jobTypeLocation: +mainData.jobTypeLocation,
        locationType: mainData.locationType,
        locations: mainData.locations ? mainData.locations : null,
        projectId: mainData.projectName.projectId,
        shiftTimings: mainData.shiftTimings.id,
        attachmentNeeded: mainData.attachmentNeeded,
      });
      formData1.append("body", body);
      formData1.append("page1", JSON.stringify(mainData));

      const userId = localStorage.getItem("userID");

      axios
        .post(
          `${UrlLinks.baseUrl}task-job/addEditJob?insertedBy=${userId}`,
          formData1,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "*/*", // Update the accept header here
            },
          }
        )
        .then((res) => {
          if (res.data.code !== 1000) {
            setIsLoading(false);
            swal(
              "Uploaded location data is not present in the system.",
              "",
              "error"
            );
          } else {
            setEligibilityJobId(res.data.response);
            setIsLoading(false);
            history.push(`/jobsdb/eligibilityCriteria/${res.data.response}`);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      swal(error, "", "error");
    }
  };

  const handleFileUpload = (e) => {
    const maxSize = 10 * 1024 * 1024;
    const files = e.target.files;
    setAttachment(files[0]);
    if (files.length > 0 && files[0].size <= maxSize) {
      const allowedTypes = [
        "video/mp4",
        "video/quicktime",
        "video/x-matroska",
        "application/pdf",
      ];
      const validFiles = Array.from(files).filter((file) =>
        allowedTypes.includes(file.type)
      );

      if (validFiles.length > 0) {
        setSelectedAttachments(validFiles);
        setFormData((prev) => ({ ...prev, attachmentNeeded: true }));
        // Clear the input value to reset it
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setFileSizeErr(false);
      } else {
        // Display an error message or toast indicating invalid file types
        console.error("Invalid file types. Please select valid files.");
      }
    } else {
      setFileSizeErr(true);
    }
  };

  const handleLocationFileUpload = (e) => {
    const files = e.target.files;
    setExcel(files[0]);
    const allowedTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    const validFiles = Array.from(files).filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validFiles.length > 0) {
      setSelectedLocationFile(validFiles);
      // Clear the input value to reset it
      if (locationFileInputRef.current) {
        locationFileInputRef.current.value = "";
      }
    } else {
      // Display an error message or toast indicating invalid file types
      console.error("Invalid file types. Please select valid files.");
    }
  };

  const removeSelectedAttachment = (index) => {
    const updatedAttachments = selectedAttachments.filter(
      (_, i) => i !== index
    );
    setSelectedAttachments(updatedAttachments);
    setFormData((prev) => ({ ...prev, attachmentNeeded: false }));
  };

  const removeAttachmentLink = () => {
    setFormData((prev) => ({
      ...prev,
      attachmentName: "",
      attachmentSize: "",
    }));
    setFormData((prev) => ({ ...prev, attachmentNeeded: false }));
  };

  const removeSelectedLocationFile = (index) => {
    const updatedAttachments = selectedLocationFile.filter(
      (_, i) => i !== index
    );
    setSelectedLocationFile(updatedAttachments);
    setExcel(null);
  };

  const Loader = () => (
    <div
      style={{
        margin: "auto",
        width: "100%",
        height: "100%",
        marginTop: "25%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <>
      {pageLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div>
              <div className={styles.mainBox}>
                <div className={styles.InputContainer}>
                  <div className={styles.BackNavigator}>
                    <div>
                      <Link to="/jobsdb" style={{ textDecoration: "none" }}>
                        <ArrowBackIcon
                          sx={{
                            color: "black",
                            fontSize: "40",
                            marginBottom: "-5px",
                          }}
                          onClick={() => {
                            setJobConfigMode("create");
                          }}
                        ></ArrowBackIcon>
                      </Link>
                      <span
                        className={styles.formHeading}
                        style={{ marginLeft: "10px" }}
                      >
                        {jobConfigMode === "create"
                          ? "Add Job 1/3"
                          : "Edit Job"}
                      </span>
                    </div>
                    {jobConfigMode === "view" && (
                      <div>
                        <button
                          className={styles.editBtn}
                          onClick={() => {
                            setJobConfigMode("edit");
                            setIsNotEditable(true);
                          }}
                        >
                          {" "}
                          <EditIcon /> <span>Edit</span>{" "}
                        </button>
                      </div>
                    )}
                  </div>
                  <br></br>

                  <div className={`${styles.InputBox} ${classes.clientName}`}>
                    <span
                      className={`${styles.formFont} ${
                        jobConfigMode === "view"
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Project name
                    </span>
                    {jobConfigMode === "view" ? (
                      <p>{formData.projectName.name}</p>
                    ) : (
                      <AutoSelect
                        data={formData}
                        setData={setFormData}
                        id="projectName"
                        multipleFlag={false}
                        label="Select Project name"
                        list={optionList.projectNameList}
                        disabled={isNotEditable}
                      />
                    )}
                  </div>
                  <div className={styles.InputBox}>
                    <span
                      className={`${styles.formFont} ${
                        jobConfigMode === "view"
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Job title
                    </span>
                    {jobConfigMode === "view" ? (
                      <p>{formData.jobTitle}</p>
                    ) : (
                      <FormControl variant="outlined">
                        <TextField
                          id="outlined-adornment-weight"
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          className={styles.textComponent}
                          label="Type Job title"
                          value={formData.jobTitle}
                          onChange={(e) => {
                            handleInputChange(e, 1);
                          }}
                          disabled={isNotEditable}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className={`${styles.InputBox} ${classes.clientName}`}>
                    <span
                      className={`${styles.formFont} ${
                        jobConfigMode === "view"
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Job type
                    </span>
                    {jobConfigMode === "view" ? (
                      <p>
                        1.)&nbsp;&nbsp; {getData.jobTypeLocationName} <br />{" "}
                        <br /> 2.)&nbsp;&nbsp; {getData.jobTypeName}
                      </p>
                    ) : (
                      <>
                        <div className={styles.labelGap10}>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              checked={formData?.jobTypeLocation === "5"}
                              onChange={(e) => handleInputChange(e, 3)}
                              disabled={isNotEditable}
                              name="jobTypeLocation"
                              key="remote"
                            />
                            Remote
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="6"
                              checked={formData?.jobTypeLocation === "6"}
                              onChange={(e) => handleInputChange(e, 3)}
                              disabled={isNotEditable}
                              name="jobTypeLocation"
                              key="field"
                            />
                            Field Job
                          </label>
                        </div>
                        <Divider />
                        <div className={styles.labelGap10}>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              checked={formData?.jobType === "2"}
                              onChange={(e) => handleInputChange(e, 4)}
                              name="jobType"
                              key="partTime"
                            />
                            Part time
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="1"
                              checked={formData?.jobType === "1"}
                              onChange={(e) => handleInputChange(e, 4)}
                              name="jobType"
                              key="fullTime"
                            />
                            Full time
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              checked={formData?.jobType === "4"}
                              onChange={(e) => handleInputChange(e, 4)}
                              name="jobType"
                              key="bothFullnPart"
                            />
                            Part time & Full time both
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={`${styles.InputBox} ${classes.clientName}`}>
                    <span
                      className={`${styles.formFont} ${
                        jobConfigMode === "view"
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Shift timings
                    </span>
                    {jobConfigMode === "view" ? (
                      <p>{formData.shiftTimings.name}</p>
                    ) : (
                      <AutoSelect
                        data={formData}
                        setData={setFormData}
                        id="shiftTimings"
                        multipleFlag={false}
                        label="Select Shift timings"
                        list={optionList.shiftTimings}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.formFont} ${styles.jobDescLabel} ${
                    jobConfigMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Job description type
                  {jobConfigMode !== "view" && (
                    <Tooltip
                      title={
                        <div
                          style={{
                            maxWidth: "192px",
                            fontSize: "14px",
                            padding: "3px 0",
                          }}
                        >
                          (upto 2000 characters allowed)
                        </div>
                      }
                    >
                      <InfoIcon
                        style={{ height: "20px", marginBottom: "-3px" }}
                      />
                    </Tooltip>
                  )}
                </div>
                <div
                  className={styles.InputContainer2}
                  style={{ border: jobConfigMode === "view" && "none" }}
                >
                  <div className={`${styles.InputBox}`}>
                    {jobConfigMode === "view" ? (
                      <>
                        <p
                          className={styles.jobDescView}
                          dangerouslySetInnerHTML={{ __html: formData.jobDesc }}
                        ></p>
                        <div
                          style={{
                            display: formData?.attachmentName
                              ? "block"
                              : "none",
                          }}
                        >
                          <span className={styles.selectedAttachmentsContainer}>
                            <a
                              href={getData.attachment}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#1C75BC" }}
                            >
                              {formData.attachmentName &&
                              formData.attachmentName.length > 20
                                ? `${formData.attachmentName.substring(
                                    0,
                                    20
                                  )}...`
                                : formData.attachmentName}
                              &nbsp;
                              <span
                                style={{
                                  color: "black",
                                  textDecoration: "underline",
                                }}
                              >
                                ({formData?.attachmentSize})
                              </span>
                            </a>
                          </span>
                        </div>
                      </>
                    ) : (
                      <TextEditor
                        setData={setFormData}
                        data={formData}
                        id="jobDesc"
                      />
                    )}
                    {validationErr.projectDescription && (
                      <FormHelperText
                        style={{ color: "red", fontSize: "12px" }}
                        id="component-error-text"
                      >
                        {validationMessage.descriptionError}
                      </FormHelperText>
                    )}
                  </div>
                  {jobConfigMode !== "view" && (
                    <div
                      className={styles.fileInputContainer2}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className={styles.attachmentBtnContainer}>
                        <label
                          htmlFor="contained-button-file"
                          className={styles.browseFileContainer}
                        >
                          <Button
                            variant="outlined"
                            component="span"
                            style={{ border: "1px solid #1C75BC" }}
                          >
                            Add attachment
                          </Button>
                        </label>
                        {selectedAttachments.length > 0 ? (
                          <span className={styles.selectedAttachmentsContainer}>
                            {selectedAttachments.map((file, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  className={styles.selectedAttachment}
                                  onClick={handleOpenUploadedFile}
                                >
                                  {file.name.length > 20
                                    ? `${file.name.substring(0, 20)}...`
                                    : file.name}
                                  &nbsp;
                                  <span style={{ color: "black" }}>
                                    {formatFileSize(file.size)}
                                  </span>
                                </div>
                                <button
                                  className={styles.fileCloseBtn}
                                  onClick={() =>
                                    removeSelectedAttachment(index)
                                  }
                                >
                                  <img src={closeSvg} alt="close" />
                                </button>
                              </>
                            ))}
                          </span>
                        ) : getData &&
                          formData.attachmentName &&
                          formData.attachmentSize &&
                          getData.attachment ? (
                          <div>
                            <span
                              className={styles.selectedAttachmentsContainer}
                            >
                              <a
                                href={getData.attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#1C75BC" }}
                              >
                                {formData.attachmentName.length > 20
                                  ? `${formData.attachmentName.substring(
                                      0,
                                      20
                                    )}...`
                                  : formData.attachmentName}
                                &nbsp;
                                <span
                                  style={{
                                    color: "black",
                                    textDecoration: "underline",
                                  }}
                                >
                                  ({formData.attachmentSize})
                                </span>
                              </a>
                              <button
                                className={styles.fileCloseBtn}
                                onClick={() => removeAttachmentLink()}
                              >
                                <img src={closeSvg} alt="close" />
                              </button>
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.browseFileLabel}>
                        Attach only MP4, MOV, MKV or PDF
                      </div>
                      <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        accept=".mp4,video/*,.mov,video/*,.mkv,video/*,.pdf,application/pdf"
                        onChange={(e) => handleFileUpload(e)}
                        ref={fileInputRef}
                      />
                      {fileSizeErr && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            paddingLeft: "15px",
                          }}
                        >
                          File size should be less than 10 MB
                        </p>
                      )}
                    </div>
                  )}
                </div>
                {formData.jobTypeLocation !== "5" && (
                  <div
                    style={{ marginTop: "54px" }}
                    className={styles.InputContainer}
                  >
                    <div className={`${styles.InputBox} ${classes.clientName}`}>
                      <span
                        className={`${styles.formFont} ${
                          jobConfigMode === "view"
                            ? styles.lightText
                            : styles.requiredField
                        }`}
                      >
                        Location
                      </span>
                      {jobConfigMode === "view" ? (
                        <LocationComponent
                          showLocationList={showLocationList}
                          getData={getData}
                          setShowLocationList={setShowLocationList}
                        />
                      ) : (
                        <>
                          <div className={styles.labelGap60}>
                            <label>
                              <input
                                type="radio"
                                value="city"
                                checked={formData?.locationType === "city"}
                                onChange={(e) => handleInputChange(e, 6)}
                                disabled={
                                  formData.is_has_job === 1
                                    ? jobConfigMode === "edit"
                                    : ""
                                }
                                name="locationType"
                                key="remote"
                              />
                              Cities
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="pincode"
                                checked={formData?.locationType === "pincode"}
                                onChange={(e) => handleInputChange(e, 6)}
                                disabled={
                                  formData.is_has_job === 1
                                    ? jobConfigMode === "edit"
                                    : ""
                                }
                                name="locationType"
                                key="field"
                              />
                              Pincodes
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="hublocations"
                                checked={
                                  formData?.locationType === "hublocations"
                                }
                                onChange={(e) => handleInputChange(e, 6)}
                                disabled={
                                  formData.is_has_job === 1
                                    ? jobConfigMode === "edit"
                                    : ""
                                }
                                name="locationType"
                                key="field"
                              />
                              Hub Locations
                            </label>
                          </div>
                          <a
                            href={locationDataTemplate}
                            download
                            target="_blank"
                            className={styles.fitContent}
                          >
                            <span
                              style={{ fontSize: "smaller", color: "#1c75bc" }}
                            >
                              Download File Template{" "}
                              <img
                                style={{
                                  paddingLeft: "1rem",
                                  paddingRight: "0.5rem",
                                }}
                                src={Download}
                                alt="download"
                              />
                            </span>
                          </a>

                          <div
                            className={`${styles.InputBox} ${classes.clientName}`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "20px",
                              width: "700px",
                            }}
                          >
                            {jobConfigMode === "view" ? (
                              <p>{formData.projectOwner.name}</p>
                            ) : (
                              <>
                                {" "}
                                <AutoSelect
                                  data={formData}
                                  setData={setFormData}
                                  disabled={
                                    !formData.locationType ||
                                    selectedLocationFile.length > 0
                                  }
                                  id="locations"
                                  multipleFlag={true}
                                  label="Select Location"
                                  list={optionList.locationList}
                                  width="75%"
                                  searchText="locationSearch"
                                />{" "}
                                <div
                                  className={styles.fileInputContainer}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div
                                    className={styles.attachmentBtnContainer}
                                    style={{ flexDirection: "column" }}
                                  >
                                    <label
                                      htmlFor="location-button-file"
                                      className={styles.browseFileContainer}
                                    >
                                      <Button
                                        variant="outlined"
                                        className={styles.uploadButton}
                                        component="span"
                                        disabled={
                                          !formData.locationType ||
                                          formData.locations.length > 0
                                        }
                                      >
                                        Browse Files
                                      </Button>
                                    </label>
                                  </div>
                                  <input
                                    style={{ display: "none" }}
                                    id="location-button-file"
                                    type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={(e) =>
                                      handleLocationFileUpload(e)
                                    }
                                    ref={locationFileInputRef}
                                    disabled={
                                      !formData.locationType ||
                                      formData.locations.length > 0
                                    }
                                  />
                                </div>{" "}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {jobConfigMode === "view" && (
                  <div
                    style={{ marginTop: "54px" }}
                    className={styles.InputContainer}
                  >
                    <div
                      className={`${styles.InputBox} ${classes.clientName}`}
                      style={{ marginBottom: "54px" }}
                    >
                      <span
                        className={`${styles.formFont} ${
                          jobConfigMode === "view"
                            ? styles.lightText
                            : styles.requiredField
                        }`}
                      >
                        Eligibility Criteria
                      </span>
                      {eligibilityData && (
                        <table
                          style={{
                            borderCollapse: "collapse",
                            border: "none",
                            width: "700px",
                          }}
                        >
                          <tbody>
                            {Object.entries(eligibilityData).map(
                              ([key, value], i) => {
                                let comp = null;
                                if (i === 0) {
                                  comp = (
                                    <tr key={key}>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        Age
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        :
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                          textTransform: "none",
                                        }}
                                      >
                                        {eligibilityData.fromAge !== "" &&
                                        eligibilityData.toAge !== ""
                                          ? `${eligibilityData.fromAge} to ${eligibilityData.toAge} Years`
                                          : eligibilityData.fromAge !== ""
                                          ? `Above ${eligibilityData.fromAge} Years`
                                          : eligibilityData.toAge !== ""
                                          ? `18 to ${eligibilityData.toAge} Years`
                                          : null}
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <div className="mustHaveConfigflex">
                                          Must Have
                                          <div
                                            className={`mustHaveConfig ${
                                              ageToggle
                                                ? "mustHaveConfigActive"
                                                : ""
                                            }`}
                                          >
                                            <div
                                              className={`mustHaveBtnConfig ${
                                                ageToggle
                                                  ? "mustHaveBtnActive"
                                                  : ""
                                              }`}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                } else if (Array.isArray(value)) {
                                  comp = (
                                    <tr key={key}>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {key}
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        :
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {value.map((obj, index) => (
                                          <span
                                            style={{
                                              marginLeft: index !== 0 && "20px",
                                            }}
                                          >{`${index + 1}. ${obj.name}`}</span>
                                        ))}
                                      </td>
                                      {key === "language" && (
                                        <td
                                          style={{
                                            border: "none",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <div className="mustHaveConfigflex">
                                            Must Have
                                            <div
                                              className={`mustHaveConfig ${
                                                languageToggle
                                                  ? "mustHaveConfigActive"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`mustHaveBtnConfig ${
                                                  languageToggle
                                                    ? "mustHaveBtnActive"
                                                    : ""
                                                }`}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      {key === "assets" && (
                                        <td
                                          style={{
                                            border: "none",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <div className="mustHaveConfigflex">
                                            Must Have
                                            <div
                                              className={`mustHaveConfig ${
                                                assetsToggle
                                                  ? "mustHaveConfigActive"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`mustHaveBtnConfig ${
                                                  assetsToggle
                                                    ? "mustHaveBtnActive"
                                                    : ""
                                                }`}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                } else if (
                                  typeof value === "object" &&
                                  value !== null
                                ) {
                                  comp = (
                                    <tr key={key}>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {key}
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        :
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {value.name}
                                      </td>
                                      {key === "gender" && (
                                        <td
                                          style={{
                                            border: "none",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <div className="mustHaveConfigflex">
                                            Must Have
                                            <div
                                              className={`mustHaveConfig ${
                                                genderToggle
                                                  ? "mustHaveConfigActive"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`mustHaveBtnConfig ${
                                                  genderToggle
                                                    ? "mustHaveBtnActive"
                                                    : ""
                                                }`}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                      {key === "education" && (
                                        <td
                                          style={{
                                            border: "none",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <div className="mustHaveConfigflex">
                                            Must Have
                                            <div
                                              className={`mustHaveConfig ${
                                                educationToggle
                                                  ? "mustHaveConfigActive"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`mustHaveBtnConfig ${
                                                  educationToggle
                                                    ? "mustHaveBtnActive"
                                                    : ""
                                                }`}
                                              ></div>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                                return comp;
                              }
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                )}

                {selectedLocationFile.length > 0 && (
                  <span
                    className={styles.selectedAttachmentsContainer}
                    style={{ marginLeft: "56px" }}
                  >
                    {selectedLocationFile.map((file, index) => (
                      <>
                        <div key={index} className={styles.selectedAttachment}>
                          {file.name}
                        </div>
                        <button
                          className={styles.fileCloseBtn}
                          onClick={() => removeSelectedLocationFile(index)}
                        >
                          <img src={closeSvg} alt="close" />
                        </button>
                      </>
                    ))}
                  </span>
                )}
                {jobConfigMode !== "view" && (
                  <div className={styles.saveCancel}>
                    <div className={styles.BtnBox}>
                      {isLoading && (
                        <span style={{ marginRight: "21px" }}>
                          Please wait...
                        </span>
                      )}
                      <Link to="/jobsdb" style={{ textDecoration: "none" }}>
                        {" "}
                        <Button
                          onClick={() => {
                            setJobConfigMode("create");
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>{" "}
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Link
                        to="/jobsdb/eligibilityCriteria/add"
                        style={{ textDecoration: "none" }}
                      ></Link>
                      <Button
                        onClick={handleSaveBtn}
                        variant="contained"
                        disabled={!goodToSave || isLoading}
                      >
                        NEXT
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={laoding}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
          {showLocationList && (
            <LocationModal
              openAdminNotes={showLocationList}
              setOpenAdminNotes={setShowLocationList}
              inForm={true}
              modelList={getData.locationArray}
            />
          )}
        </>
      )}
    </>
  );
};
export default JobConfigForm;
