import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useFilters,
  useExpanded,
} from "react-table";
import urlLinks from "../../UrlLinks";
import SampleResponse from "./SampleResponse";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import { numberFormat } from "../../Utils/CommonFunctions";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import { Link } from "react-router-dom";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    // width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
}));

const ExternalWorker = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { showToast } = useContext(AuthContext);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchByUrl, setSearchByUrl] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [financialAdmin, setFinancialAdmin] = useState(false);
  const [filtersUrl, setFiltersUrl] = useState("");
  const [searchByAuto, setsearchByAuto] = useState("");

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const [filters, setFilters] = useState({
    searchBy: "",
    billDate: [null, null],
    zone: "",
    facility_state: "",
    facility_name: "",
    payment_status: "",
    worker_status: "",
    worker_type: "",
    payment_type: "",
    advanceTaken: "",
    bankDetails: "",
    bankDetailsVerified: "",
    panStatus: "",
    modalFlag: "",
    All: true,
    Positive: false,
    Negative: false,
    Zero: false,
  });

  const ApplySort = () => {
    if (sortBy !== 0) setSortUrl("&partnerId=" + sortBy);
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  const handleSearch = () => {
    setSearchByUrl(`&searchBy=${searchBy}`);
  };

  const handleSearchClose = () => {
    setSearchBy("");
    setSearchByUrl("");
  };

  const ApplyFilters = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=2019-01-01&billTo=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][0] && filters["billDate"][1])
      url = `${url}&billFrom=${moment(filters["billDate"][0]).format(
        "YYYY-MM-DD"
      )}&billTo=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["zone"]) {
      url = `${url}&zone=${filters["zone"]}`;
    }
    if (filters["facility_state"]) {
      url = `${url}&facility_state=${filters["facility_state"].name}`;
    }
    if (filters["facility_name"]) {
      url = `${url}&facility_name=${filters["facility_name"].name}`;
    }
    if (filters["payment_status"]) {
      url = `${url}&payment_status=${filters["payment_status"]}`;
    }
    if (filters["worker_status"]) {
      url = `${url}&worker_status=${filters["worker_status"]}`;
    }
    if (filters["worker_type"] || filters["worker_type"] === 0) {
      url = `${url}&isGold=${filters["worker_type"]}`;
    }
    if (filters["payment_type"]) {
      url = `${url}&paymentType=${filters["payment_type"]}`;
    }
    if (filters["advanceTaken"] || filters["advanceTaken"] === 0) {
      url = `${url}&advanceTaken=${filters["advanceTaken"]}`;
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bank_details=${filters["bankDetails"]}`;
    }
    if (
      filters["bankDetailsVerified"] ||
      filters["bankDetailsVerified"] === 0
    ) {
      url = `${url}&verification_status=${filters["bankDetailsVerified"]}`;
    }
    if (filters["panStatus"] || filters["panStatus"] === 0) {
      url = `${url}&isPanVerified=${filters["panStatus"]}`;
    }
    if (filters["modalFlag"] || filters["modalFlag"] === 0) {
      url = `${url}&is_lma_worker=${filters["modalFlag"]}`;
    }
    setFiltersUrl(url);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    ApplyFilters();
  }, [filters]);

  const workerLedgerListingCols = useMemo(
    () => [
      {
        Header: "OkayGo ID",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Verification",
        accessor: "bankVerification",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Invoices",
        Cell: (row) => {
          return (
            <Link
              to={{
                pathname: `/particularLedger/${row.row.original.okaygoId}`,
                rowData: row.row.original,
              }}
              target="_blank"
              className="invoiceLink"
            >
              {row.row.original.invoices} {"Invoices >"}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Credit",
        accessor: "totalCredit",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Debit",
        accessor: "totalDebit",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Net Balance",
        accessor: "netBalance",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [filters, filtersUrl, sortBy, sortUrl, perPage, currentPage]
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      extractedData.push({
        workerId: el.workerId || "",
        okaygoId: el.okaygoId || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        bankVerification:
          el.bankVerification != null
            ? el.bankVerification === 1
              ? "Yes"
              : "No"
            : "-",

        invoices: el.invoices ? el.invoices : "-",
        totalCredit: el.totalCredit ? numberFormat(el.totalCredit) : "",
        totalDebit: el.totalDebit ? numberFormat(el.totalDebit) : "",
        netBalance: el.netBalance ? numberFormat(el.netBalance) : "",
      });
      return true;
    });
    return extractedData;
  };

  //Function to create All record export data
  const columns = workerLedgerListingCols;
  const updateMyData = () => {
    getWorkerLedgerListing(perPage, currentPage);
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getWorkerLedgerListing = (rows, pageNo) => {
    setLoading(true);
    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.workerLedgerListing}?page_no=${
        pageNo - 1
      }&rows=${rows}${
        !filters.All
          ? `&value=${
              filters.Zero
                ? 0
                : filters.Positive
                ? 1
                : filters.Negative
                ? -1
                : ""
            }`
          : ""
      } ${searchByUrl}`
    )
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // Sample Response
        const response = SampleResponse;
        setTotalRows(response.totalElements);
        setTotalPages(response.totalPages);
        if (currentPage > response.totalPages) setCurrentPage(1);
        setData(makeData(response.response));
        setLoading(false);
      });
  };

  useEffect(() => {
    getWorkerLedgerListing(perPage, 1);
  }, [
    filtersUrl,
    sortUrl,
    localStorage.getItem("roleType"),
    searchByUrl,
    filters,
  ]);

  const handlePageChange = (page) => {
    getWorkerLedgerListing(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    visibleColumns,
    state: { selectedRowIds, expanded },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: [""] },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getWorkerLedgerListing(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    rows.map((record, index) => {
      if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
        selectedRowIds[index] = false;
      }
    });
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "OkayGo ID"
                      ? "stickWorkerIdShift zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameShift zIndex1080 header"
                      : "long-header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()} className="row">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            cell.column.Header === "OkayGo ID"
                              ? "stickWorkerIdShift  zIndex540"
                              : cell.column.Header === "Net Balance"
                              ? "leftAlignLedgerTableRow"
                              : cell.column.Header === "Name"
                              ? "stickWorkerNameShift zIndex540"
                              : cell.column.id === "selection"
                              ? "stickCheckbox zIndex540"
                              : cell.column.Header === "Mark onHold"
                              ? financialAdmin
                                ? ""
                                : "displayNone"
                              : "cell"
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  // Penny testing
  const handleFilterStatus = (event, name) => {
    if (name === "Positive") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          Positive: true,
          Negative: false,
          Zero: false,
          All: false,
        });
      }
    } else if (name === "Negative") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          Positive: false,
          Negative: true,
          Zero: false,
          All: false,
        });
      }
    } else if (name === "Zero") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          Positive: false,
          Negative: false,
          Zero: true,
          All: false,
        });
      }
    } else if (name === "All") {
      if (!filters[name]) {
        setFilters({
          ...filters,
          Positive: false,
          Negative: false,
          Zero: false,
          All: true,
        });
      }
    }
  };

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid>
          <span className="fontSize24 fontWeightBold">Worker Ledger</span>
        </Grid>
        <Grid item xs={12} className="flexDisplay" alignItems="flex-start">
          <Grid
            item
            xs={5}
            className="flexDisplay padding0 paddingTop8"
            direction="row"
            justify="space-between"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder={"Search by OkayGo ID "}
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            className="flexDisplay padding0 paddingTop8"
            direction="row"
          >
            <Button
              onClick={(event) => handleFilterStatus(event, "All")}
              className={`statusButtons marginRight20 ${
                filters.All ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              All
            </Button>
            <Button
              onClick={(event) => handleFilterStatus(event, "Positive")}
              className={`statusButtons marginRight20 ${
                filters.Positive ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Positive
            </Button>
            <Button
              onClick={(event) => handleFilterStatus(event, "Negative")}
              className={`statusButtons marginRight20 ${
                filters.Negative ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Negative
            </Button>

            <Button
              onClick={(event) => handleFilterStatus(event, "Zero")}
              className={`statusButtons ${
                filters.Zero ? "blueColorBorder" : "greyColorBorder"
              }`}
            >
              Zero
            </Button>
          </Grid>
          <Grid
            item
            xs={3}
            className="flexDisplay padding0"
            direction="row"
            justify="space-between"
          ></Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
