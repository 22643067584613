import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  forwardRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import "./indeterminateCheckbox.css";
import styled from "styled-components";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import Pagination from "../../Utils/Pagination";
import * as moment from "moment";
import NoRecord from "./Assets/noRecord.svg";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import UrlLinks from "../../UrlLinks";
import ExportButton from "../../Utils/functions/ExportButton";
import RemoveExtraSpaces from "../../Utils/functions/RemoveExtraSpaces";
import SearchBar from "../../Utils/functions/SearchBar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Checkbox, Drawer } from "@material-ui/core";
import JobFilter from "../../Utils/FilterComponents/JobFilter";
import filterIcon from "../../assets/filterIcon.png";
import FilterIconMarked from "../../assets/FilterIconMarked.svg";
import FilterDrawer from "./components/FilterDrawer";
import DocumentDownload from "./components/downloadDocuments";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc;
      color: #ffffff;
    }
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({}));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <React.Fragment>
      <Checkbox ref={resolvedRef} {...rest} />
    </React.Fragment>
  );
});

const AttendanceManagement = (props) => {
  /** accessing states from redux store **/

  const { users, applicationDb } = useSelector((state) => state);
  const {
    loader,
    currentPage,
    perPage,
    totalRows,
    totalPages,
    openFilterDrawer,
    isFilter,
    allRecord,
  } = users;
  const { curLeadId } = applicationDb;

  /** useDispatch is used to update states to redux store **/

  const dispatch = useDispatch();
  const {
    setLoader,
    setCurrentPage,
    setPerPage,
    setTotalRows,
    setTotalPages,
    setSelectedAssignIds,
    setSelectedUserIds,
    setOpenFilterDrawer,
    setIsFilter,
    setAllRecord,
  } = bindActionCreators(actionCreators, dispatch);

  /** other states **/

  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [searchByAuto, setSearchByAuto] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [selfieLoader, setSelfieLoader] = useState(null);
  const [searchError, setSearchError] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedTime, setSelectedTime] = useState([null, null]);
  const [leadCityId, setLeadCityId] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    id: "-1",
    name: "Change Project",
  });
  const [filters, setFilters] = useState({
    attendanceDate: [null, null],
    attendanceTime: [null, null],
    city: "",
    projectId: "",
    searchBy: "",
  });

  const resetFiltersWithoutSearch = () => {
    setIsFilter(false);
    const filterList = {
      attendanceDate: [null, null],
      attendanceTime: [null, null],
      city: "",
      projectId: filters.projectId,
      searchBy: filters.searchBy,
    };

    if (openFilterDrawer) {
      setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "");
    }

    setFilters(filterList);
    setSelectedTime([null, null]);
    setLeadCityId([]);
    setCurrentPage(1);
    return filterList;
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),

        columns: [
          {
            id: "selectionId",
            Cell: ({ row }) => (
              <div id="check1">
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  style={{ display: "flex", justifyItems: "center" }}
                />
              </div>
            ),
          },
        ],
      },
      {
        Header: "Date",
        columns: [
          {
            id: "dateId",
            accessor: "checkinDate",
            Cell: (row) => {
              return (
                <div>
                  {row.row.original.checkinDate
                    ? moment(row.row.original.checkinDate).format("DD/MM/YYYY")
                    : "-"}
                </div>
              );
            },
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "OkayGo ID",
        columns: [
          {
            id: "okaygoId",
            accessor: "okaygoId",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Name",
        columns: [
          {
            id: "nameId",
            accessor: "firstName",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Phone No",
        columns: [
          {
            id: "phoneNoId",
            accessor: "phoneNumber",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Check In Time",
        columns: [
          {
            id: "checkinTimeId",
            accessor: "checkinTime",
            Cell: (row) => {
              return (
                <div>
                  {row.row.original.checkinTime
                    ? moment(row.row.original.checkinTime, "HH:mm:ss").format(
                        "h:mm A"
                      )
                    : "-"}
                </div>
              );
            },
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Check Out Time",
        columns: [
          {
            id: "checkoutTimeId",
            accessor: "checkoutTime",
            Cell: (row) => {
              return (
                <div>
                  {row.row.original.checkoutTime
                    ? moment(row.row.original.checkoutTime, "HH:mm:ss").format(
                        "h:mm A"
                      )
                    : "-"}
                </div>
              );
            },
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Location",
        columns: [
          {
            id: "locationId",
            accessor: "checkinLocation",
            Cell: (row) => {
              return (
                <div style={{ width: "561px" }}>
                  {row.row.original.checkinLocation}
                </div>
              );
            },
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Start Time Selfie",
        columns: [
          {
            id: "selfieId",
            accessor: "selfieLink",
            Cell: (props) => (
              <DocumentDownload
                {...props}
                uniqueId="selfieId"
              />
            ),
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "End Time Selfie",
        columns: [
          {
            id: "checkoutSelfieLinkId",
            accessor: "checkoutSelfieLink",
            Cell: (props) => (
              <DocumentDownload
                {...props}
                uniqueId="checkoutSelfieLinkId"
              />
            ),
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Source",
        columns: [
          {
            id: "sourceId",
            accessor: "source",
            defaultCanFilter: false,
            defaultCanSort: false,
          },
        ],
      },
      {
        Header: "Time Slot",
        columns: [
          {
            id: "shift1",
            Header: "Time Shift 1",
            accessor: "slot1",
            Cell: (row) => {
              const slot1Object = JSON.parse(row.row.original.slot1);
              return (
                <div>
                  {slot1Object
                    ? slot1Object?.checkinTime
                      ? moment(slot1Object.checkinTime, "HH:mm:ss").format(
                          "h:mm A"
                        )
                      : "Not marked"
                    : "-"}
                </div>
              );
            },
          },
          {
            id: "shift2",
            Header: "Time Shift 2",
            accessor: "slot2",
            Cell: (row) => {
              const slot2Object = JSON.parse(row.row.original.slot2);
              return (
                <div>
                  {slot2Object
                    ? slot2Object?.checkinTime
                      ? moment(slot2Object.checkinTime, "HH:mm:ss").format(
                          "h:mm A"
                        )
                      : "Not marked"
                    : "-"}
                </div>
              );
            },
          },
          {
            id: "shift3",
            Header: "Time Shift 3",
            accessor: "slot3",
            Cell: (row) => {
              const slot3Object = JSON.parse(row.row.original.slot3);
              return (
                <div>
                  {slot3Object
                    ? slot3Object?.checkinTime
                      ? moment(slot3Object.checkinTime, "HH:mm:ss").format(
                          "h:mm A"
                        )
                      : "Not marked"
                    : "-"}
                </div>
              );
            },
          },
          {
            id: "shift4",
            Header: "Time Shift 4",
            accessor: "slot4",
            Cell: (row) => {
              const slot4Object = JSON.parse(row.row.original.slot4);
              return (
                <div>
                  {slot4Object
                    ? slot4Object?.checkinTime
                      ? moment(slot4Object.checkinTime, "HH:mm:ss").format(
                          "h:mm A"
                        )
                      : "Not marked"
                    : "-"}
                </div>
              );
            },
          },
          {
            id: "shift5",
            Header: "Time Shift 5",
            accessor: "slot5",
            Cell: (row) => {
              const slot5Object = JSON.parse(row.row.original.slot5);
              return (
                <div>
                  {slot5Object
                    ? slot5Object?.checkinTime
                      ? moment(slot5Object.checkinTime, "HH:mm:ss").format(
                          "h:mm A"
                        )
                      : "Not marked"
                    : "-"}
                </div>
              );
            },
          },
        ],
      },
    ],
    [selectedOption]
  );

  // Function to update the Table Data
  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const handleSelfieDownload = (selfieUrl, data) => {
    setSelfieLoader(data.attendanceId);
    // const isLocal = window.location.hostname === "localhost";
    // if (selfieUrl.startsWith("http://")) {
    //   selfieUrl = isLocal
    //     ? selfieUrl
    //     : "https" + selfieUrl.substring(4, selfieUrl.length);
    // }

    fetch(selfieUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectURL;
        const dateFormat = moment(data.checkinDate)
          .format("DD/MM/YYYY")
          .replace(/_/g, "/");
        link.download = `${data.firstName}_${data.okaygoId}_${dateFormat}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSelfieLoader(null);
      });
  };

  const handleClick = (event, index, type) => {
    switch (type) {
      case "attendanceTimeFrom":
        setSelectedTime([event, selectedTime[1]]);
        setFilters({
          ...filters,
          attendanceTime: [
            moment(event, "ddd MMM DD YYYY HH:mm:ss ZZ").format("HH:mm:ss"),
            filters.attendanceTime[1],
          ],
        });
        break;
      case "attendanceTimeTo":
        setSelectedTime([selectedTime[0], event]);
        setFilters({
          ...filters,
          attendanceTime: [
            filters.attendanceTime[0],
            moment(event, "ddd MMM DD YYYY HH:mm:ss ZZ").format("HH:mm:ss"),
          ],
        });
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setSearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
    setSearchByAuto("");
  };

  const updateFilterUrl = () => {
    let url = "";

    // Search By
    if (filters["searchBy"] !== "") {
      url = `${url}&searchBy=${filters["searchBy"]}`;
    }

    // Date
    if (filters["attendanceDate"][0] && !filters["attendanceDate"][1])
      url = `${url}&dateFrom=${moment(filters["attendanceDate"][0]).format(
        "YYYY-MM-DD"
      )}&dateTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["attendanceDate"][0] && filters["attendanceDate"][1])
      url = `${url}&dateFrom=2019-01-01&dateTo=${moment(
        filters["attendanceDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["attendanceDate"][0] && filters["attendanceDate"][1])
      url = `${url}&dateFrom=${moment(filters["attendanceDate"][0]).format(
        "YYYY-MM-DD"
      )}&dateTo=${moment(filters["attendanceDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    // Time
    if (filters["attendanceTime"][0] && !filters["attendanceTime"][1])
      url = `${url}&timeFrom=${filters.attendanceTime[0].toString()}`;
    if (!filters["attendanceTime"][0] && filters["attendanceTime"][1])
      url = `${url}&timeTo=${filters.attendanceTime[1].toString()}`;
    if (filters["attendanceTime"][0] && filters["attendanceTime"][1])
      url = `${url}&timeFrom=${filters.attendanceTime[0].toString()}&timeTo=${filters.attendanceTime[1].toString()}`;

    // City
    if (filters["city"]) {
      url = `${url}&city=${filters["city"]}`;
    }

    return url;
  };

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  //Function to create export data as per the main listing order
  const makeAttendanceExportData = (response) => {
    let extractedData = [];

    response.map((el) => {
      const slot1Object = JSON.parse(el.slot1);
      const slot2Object = JSON.parse(el.slot2);
      const slot3Object = JSON.parse(el.slot3);
      const slot4Object = JSON.parse(el.slot4);
      const slot5Object = JSON.parse(el.slot5);
      extractedData.push({
        Date: el.checkinDate ? moment(el.checkinDate).format("DD/MM/YYYY") : "",
        OkaygoId: el.okaygoId || "",
        Name: el.firstName || "",
        PhoneNo: el.phoneNumber || "",
        "Check In Time": el.checkinTime
          ? moment(el.checkinTime, "HH:mm:ss").format("h:mm A")
          : "",
        "Check Out Time": el.checkoutTime
          ? moment(el.checkoutTime, "HH:mm:ss").format("h:mm A")
          : "",
        Address: el.checkinLocation || "",
        "Start Time Selfie": el.selfieLink || "",
        "End Time Selfie": el.checkoutSelfieLink || "",
        Source: el.source || "",
        "Slot 1": slot1Object
          ? slot1Object.checkinTime
            ? moment(slot1Object.checkinTime, "HH:mm:ss").format("h:mm A")
            : "Not Marked"
          : "",
        "Slot 2": slot2Object
          ? slot2Object.checkinTime
            ? moment(slot2Object.checkinTime, "HH:mm:ss").format("h:mm A")
            : "Not Marked"
          : "",
        "Slot 3": slot3Object
          ? slot3Object.checkinTime
            ? moment(slot3Object.checkinTime, "HH:mm:ss").format("h:mm A")
            : "Not Marked"
          : "",
        "Slot 4": slot4Object
          ? slot4Object.checkinTime
            ? moment(slot4Object.checkinTime, "HH:mm:ss").format("h:mm A")
            : "Not Marked"
          : "",
        "Slot 5": slot5Object
          ? slot5Object.checkinTime
            ? moment(slot5Object.checkinTime, "HH:mm:ss").format("h:mm A")
            : "Not Marked"
          : "",
      });
      return true;
    });
    return extractedData;
  };

  //Function to call export data
  const createAllRecordExcel = async () => {
    setAllRecordLoading(true);
    let excelExportedBy = `&export=${localStorage.getItem(
      "userID"
    )}&exportExcel=1`;

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.projectListing.getAttendanceByProjectId}${selectedOption.id}${excelExportedBy}${filterUrl}`
    )
      .then((response) => {
        if (response) {
          setAllRecord(makeAttendanceExportData(response.data.response));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //Function to call main listing.
  const getExternalWorkerList = async (rows, pageNo) => {
    setAllRecord([]);
    if (localStorage.getItem("roleType")) {
      setLoader(true);

      let mainUrl = `${urlLinks.baseUrl}${
        urlLinks.projectListing.getAttendanceByProjectId
      }${selectedOption.id}&page_no=${pageNo - 1}&rows=${rows}${filterUrl}`;

      try {
        const response = await Axios.get(mainUrl);
        if (response) {
          setTotalRows(response.data.response?.totalElements);
          setTotalPages(response.data.response?.totalPages);
          if (currentPage > response.data.response?.totalPages) {
            setCurrentPage(1);
          }
          setData(response.data.response ? response.data.response.content : []);
        }
        setLoader(false);
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error:", error);
          setLoader(false);
        }
      }
    }
  };

  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const makeProjectList = (data) => {
    let list = [];
    // const projectIdsArray = localStorage.getItem("projectId").split(",");
    const projectIdsArray = ['67'] //coded for Mettle Invigilation Checklist
    data.map((el) => {
      if (localStorage.getItem("roleType") === "23") {
        const isIdPresent = projectIdsArray.includes(el.projects_id.toString());
        if ((el.attendance == 1 || el.attendance_history == 1) && isIdPresent) {
          list.push({
            id: el.projects_id,
            name: el.project_name,
          });
        }
      } else {
        if (el.attendance == 1 || el.attendance_history == 1) {
          list.push({
            id: el.projects_id,
            name: el.project_name,
          });
        }
      }
    });
    setSelectedOption({
      id: list[0].id,
      name: list[0].name,
    });
    return list;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      handleSelfieDownload,
      selfieLoader,
      showToast,
      setLoader,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()}>
        <thead className="thead">
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
              {headerGroup.headers.map((column, columnIndex) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    key={columnIndex}
                    className={
                      column.render("Header") === "Time Slot"
                        ? `header textAlignCenter`
                        : column.render("Header") === "Time Shift 1" ||
                          column.render("Header") === "Time Shift 2" ||
                          column.render("Header") === "Time Shift 3" ||
                          column.render("Header") === "Time Shift 4" ||
                          column.render("Header") === "Time Shift 5"
                        ? `header3 row2HeaderShift`
                        : column.render("Header")?.props?.column.originalId ===
                          "selection"
                        ? `header stickCheckbox2 zIndex1080 ${styles.selection}`
                        : column.render("Header")?.props?.column.id ===
                          "selectionId"
                        ? `header3 stickCheckbox2 zIndex1080`
                        : column.render("Header") === "Name"
                        ? `stickAttendanceWorkerName zIndex1080 header`
                        : column.render("Header")?.props?.column.id === "nameId"
                        ? `stickAttendanceWorkerName zIndex1080 header3`
                        : rowIndex === 0
                        ? "header"
                        : "header3"
                    }
                  >
                    <span style={{ paddingRight: "" }}>
                      {column.render("Header")}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={j}
                        className={`${
                          cell.column.id === "nameId"
                            ? `stickAttendanceWorkerName zIndex540`
                            : cell.column.id === "selectionId"
                            ? "stickCheckbox2 zIndex540"
                            : "cell"
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  /* Useffects */

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    if (
      filters.attendanceDate[0] !== null ||
      filters.attendanceDate[1] !== null ||
      filters.attendanceTime[0] !== null ||
      filters.attendanceTime[1] !== null ||
      filters.city !== "" ||
      filters.projectId !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filterUrl]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setIsRowSelected(disableButton);

      const indexValues = Object.entries(selectedRowIds);
      let assignIdList = [];
      let userIdList = [];
      indexValues.map((id) => {
        if (id[1]) {
          assignIdList.push(data[parseFloat(id[0])].assignId);
          userIdList.push(data[parseFloat(id[0])].userId);
        }
        return true;
      });
      assignIdList = assignIdList.join(",");
      setSelectedAssignIds(assignIdList);
      setSelectedUserIds(userIdList);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    let searchByVar = RemoveExtraSpaces(searchBy);
    let count = searchByVar.split(/[ ,]+/).length;
    if (count > 100) {
      setSearchError(true);
    } else {
      setSearchError(false);
    }
  }, [searchBy]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto, selectedOption]);

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setCurrentPage(1);
  }, [filterUrl, localStorage.getItem("roleType"), selectedOption]);

  useEffect(() => {
    // API for Project List
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.projectListing.mainListing}`)
      .then((response) => {
        setProjectList(makeProjectList(response.data.response));
      })
      .catch((err) => {
        showToast("error", "Could not able to fetch job listing");
      });
  }, []);

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12} className="flexDisplay marginBottom8">
          <Grid item xs={12} className="flexDisplay">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "15px",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {localStorage.getItem("roleType") === "23"
                ? "Mettl Invigilation Checklist"
                : selectedOption.id === "-1"
                ? "Select Project"
                : selectedOption.name}
            </div>
            {localStorage.getItem("roleType") !== "23" && (
              <JobFilter
                uniqueKey="attendanceProject"
                jobFilterOption={""}
                setJobFilterOption={null}
                jobList={projectList}
                disabled={false}
                filters={filters}
                setFilters={setFilters}
                id="projectId"
                statusId="jobStatus"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                jobFilterOptionList={[]}
                placeholder="Type or search the jobs"
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className="flexDisplay">
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <SearchBar
              uniqueId="attendanceConfig"
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchError={searchError}
              filters={filters}
              setFilters={setFilters}
              handleSearch={handleSearch}
              handleSearchClose={handleSearchClose}
              ApplyFilters={ApplyFilters}
              setFilterUrl={setFilterUrl}
              placeholder="Search by Contact number/ OkayGo ID"
              buttonName="Search"
              searchInstructions="Use comma or space as delimiter to search multiple OkayGo Id. Max limit 100."
              warningMessage=" Max limit reached. Search 100 pin max."
            />
          </Grid>
          <Grid item xs={6} className={`${styles.itemRowReverse} flexDisplay`}>
            <button
              className={styles.filterButton}
              style={{ marginLeft: "16px" }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              {!isFilter ? (
                <img src={filterIcon} alt="filterIcon" />
              ) : (
                <img src={FilterIconMarked} alt="filterIcon" />
              )}
              Filters
            </button>
            <ExportButton
              uniqueKey="AttendanceConfig"
              createAllRecordExcel={createAllRecordExcel}
              allRecordLoading={allRecordLoading}
              filename="Attendance Management.csv"
              buttonName="Export to Excel"
              loadingMessage="Please wait ... it might take few minutes."
              downloadButton="Download now"
            />
          </Grid>
        </Grid>
        {loader ? (
          loaderBar
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openFilterDrawer && (
          <Drawer
            anchor="right"
            open={openFilterDrawer}
            onClose={() => {
              setLeadCityId(curLeadId.leadCity);
              setOpenFilterDrawer(false);
            }}
          >
            <FilterDrawer
              handleClose={() => setOpenFilterDrawer(false)}
              filters={filters}
              setFilters={setFilters}
              ApplyFilters={ApplyFilters}
              leadCityId={leadCityId}
              setLeadCityId={setLeadCityId}
              resetFilters={resetFiltersWithoutSearch}
              handleChange={handleClick}
              selectedTime={selectedTime}
            ></FilterDrawer>
          </Drawer>
        )}
      </Grid>
    </Styles>
  );
};

export default AttendanceManagement;
